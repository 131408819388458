html, body {
  padding: 0;
  margin: 0;
}

#root {
  width: 100vw;
  height: 100vh;
}

.r3f-canvas {
  z-index: 0;
}

.controls {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  padding: 1rem;
}